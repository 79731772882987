import api from "../api";

const apiVersion = process.env.REACT_APP_API_VERSION;

export default {
    getAllPeople: function () {
        return api.get(`${apiVersion}/persona/`);
    },

    getProfile: function () {
        return api.get(`${apiVersion}/usuario/me/?include=persona`);
    },

    updateProfile: function (data) {
        return api.patch(`${apiVersion}/perfil-usuario/me/?include=ubicacion`, { data: data },
            { headers: { "Content-Type": "application/vnd.api+json" } }
        );
    },

    updateFotoPersona: function (id, data) {
        return api.patch(
            `${apiVersion}/perfil-usuario/${id}/actualizar-avatar/`,
            data,
            {
                headers: {
                    "Content-Type":
                        "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
                },
            }
        );
    },
};
