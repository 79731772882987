import createReducer from "src/redux/createReducer";
import * as types from "./types";
import util from "src/extras/functions";

const initialState = {
    indicadorSeleccionado: undefined
};
const IndicadorReducer = createReducer(initialState, {
    [types.UPDATE_INDICADOR_SELECCIONADO](state, action) {
        return { ...state, indicadorSeleccionado: action.payload };
    }
});

export default IndicadorReducer;