const resources = {

    pt: {
        translation: {
            "Usuario": "usuário",
            "Clave": "senha",
			"Correo electrónico":"Correio eletrônico",
            "Ingresar": "Entrem",
            "Ingrese su clave": "coloque sua senha", 
            "Ingrese su usuario": "entre com seu nome de usuário",
            "Iniciar Sesion": "Iniciar sessão",
            "Inicio": "Início",		
			"Seleccionar Plan": "Selecione o plano",
			"Acciones": "Ações",
            "Acción": "Açao",			   
			"Medición": "Medição",
            "Mediciones": "Medições",
            "Metas": "Metas",
            "No selecciono ningún plan.": "Eu não seleciono nenhum plano.",										 
			"Filter": "Filtro",
            "Agregar acción": "Acrescentar ação",
            "Detalle": "Detalhe",
            "Eliminar": "Remover",
			"Editar": "Editar",
			"Indicadores": "Indicadores/Requisitos",
			"Perfil": "Perfil",
			"Salir": "Saia",
			"Capitulos del Referencial":"Capítulo da Referência Técnica",
			"Seleccionar Capitulos del Referencial": "Selecione o capítulo da Referência Técnica",
			"Continuar": "Prosseguir",
			"Ultima medición": "Última medição",
			"Proxima meta": "Próxima meta",
			"Acciones realizadas": "Ações realizadas",
			"Nombre": "Nome",
			"Fecha de inicio": "Data de início",
            "Fecha de finalización": "Data de finalização",
            "Detalle de Acciones": "Detalhe de Ações",
            "Editar Acción": "Editar Ação",
            "Guardar": "Salvar",
            "Seleccionar ejes": "Selecione os eixos",
            "Alta de Acciones": "Registro de Ações",
            "Estás seguro de eliminar la accion": "Tem certeza que quer remover a ação",
			"Estás seguro de eliminar la medición": "Tem certeza de que deleta a medição",
			"Estás seguro de eliminar la meta": "Tem certeza de que deleta a meta",
			"Su accion fue modificada con éxito.": "Sua ação foi alterada com sucesso.",
			"Su accion fue eliminada con éxito.": "Sua ação foi removida com sucesso.",		
            "Su accion fue registrada con éxito.": "Sua ação foi registrada com sucesso.",
			"Agregar medición": "Adicionar medição",
            "Alta de Medición": "Registro de Medição",
            "Seleccionar archivo": "Selecione o arquivo",
            "Ningun archivo seleccionado": "Nenhum arquivo selecionado",
            "Indicador sin metas": "Indicador/Requisito sem metas",
            "No seleccionó una Meta": "Nenhuma meta foi selecionada",
			"Tipo de Indicador": "Tipo do Indicador",
			"Descripción del Indicador": "Descrição do Indicador",
			"Justificación / Objetivo del Indicador": "Justificativa / Objetivo do Indicador",
			"Fuente/Origen de los datos para calcular el indicador": "Fonte / Origem dos dados para calcular o indicador",
			"Frecuencia de Medición": "Frequência de Medição",
			"Unidad de Medida": "Unidade de medida",
			"Método o Formula de cálculo": "Método de cálculo ou fórmula",
			"Responsable": "Responsável",
			"Áreas responsables": "Áreas responsáveis",
			"Área responsable": "Área responsável",
			"Detalle de Meta": "Meta Detalhe",
			"Editar Meta": "Editar Meta",
			"Alta de Meta": "Altas do Meta",			
			"Agregar meta": "Adicionar meta",
			"Su meta fue modificada con éxito.": "Seu meta foi modificado com sucesso.",
			"Su meta fue eliminada con éxito.": "Sua meta foi removida com sucesso.",		
			"Su meta fue registrada con éxito.": "Seu objetivo foi registrado com sucesso.",
			"Valor de Meta": "Valor da meta",
			"Editar Medicion": "Editar Medição",										 
			"Fecha de Meta": "Data Meta",
			"Detalle de Meta": "Detalhe da Meta ",
            "Editar Meta": "Editar Meta",
            "Ultima medición": "Ultima medição",
            "Ultima meta": "Ultima meta",
			"Agregar medición": "Adicionar medição",
			"Detalle de Medición": "Detalhe de Medição",
			"Su medición fue registrada con éxito.": "Sua medição foi registrado com sucesso.",
			"Su medición fue eliminada con éxito.": "Sua medição foi removida com sucesso.",
			"Su medición fue modificada con éxito.": "Sua medição foi modificada com sucesso.",
			"Editar Medicion": "Editar Medição",
			"Valor": "Valor",
			"Fecha de Medida": "Data de Medida",
			"Fecha de proxima medida": "Data da próxima medida",
			"Meta": "Meta",
			"No seleccionó una Meta": "Não selecionou uma meta",
			"Tipo de desvío": "Tipo de desvio",
			"Justificación del desvío": "Justificativa do desvio",
			"Medio de verificación": "Meios de verificação",
			"Seleccionar archivo": "Selecione o arquivo",
			"Ningun archivo seleccionado": "Nenhum arquivo selecionado",
			"Indicador sin metas": "Indicador/Requisito sem alvos",
			"Agregar acción": "Adicionar ação",
			"Metas": "Metas",
			"Fecha de inicio previsto": "Data de início",
			"Fecha de finalización prevista": "Data de finalização",
			"Presupuesto previsto": "Orçamento estimado",
			"Presupuesto ejecutado": "Orçamento executado",
			"Acciones relacionadas": "Ações Relacionadas",
			"Observaciones": "Observações",
			"Alta de Acciones": "Registro de Ação",
			"Estás seguro de eliminar la accion?": "Tem certeza de que deleta a ação?",
			"Su accion fue modificada con éxito.": "Sua ação foi modificada com sucesso.",
			"Su accion fue registrada con éxito.": "Sua ação foi registrada com sucesso.",
			"Su accion fue eliminada con éxito.": "Sua ação foi removida com sucesso.",		
			"Detalle de Acciones": "Detalhe de Ação",
			"Editar Acción": "Editar Ação",
			"Acciones realizadas": "Ações realizadas",
            "Agregar Indicador": "Adicionar Indicador/Requisito",
			"Detalle de Indicador": "Detalhe do Indicador/Requisito",											   
            "Capítulos del Referencial": "Capítulos do Referencial",
            "Mediciones y Metas": "Medições e Metas",
            "Valor Medición": "Valor de Medição",
            "Fecha Medición": "Data de Medição",
            "Fecha Meta": "Data Meta",
			"No se pudo cargar la actividad": "Incapaz de carregar a ação",
			"Atención":"Atenção",
			"No se pudo eliminar la acción":"A ação não pôde ser removida",
			"No se pudo eliminar el indicador":"Indicador/Requisito não pôde ser removido",
			"Estás seguro de eliminar la acción":"Tem certeza de que deseja excluir a ação",
			"Estás seguro de eliminar el indicador":"Tem certeza de que deseja excluir o indicador/requisito",
			"Cargando":"Carregando",
			"Debe seleccionar al menos un eje para continuar":"Pelo menos um eixo deve ser selecionado para continuar",
			"No hay un formulario vinculado":"Não há formulário vinculado",
			"Este plan no tiene Capítulos del Referencial":"Este plano não possui Capítulos do Referencial",
			"Seleccione al menos una meta":"Selecione pelo menos uma meta",
			"Seleccione al menos un indicador":"Selecione pelo menos um indicador/requisito",
			"Ingrese una fecha de inicio":"Insira uma data de início",
			"Ingrese una fecha de fin":"Insira uma data de término",
			"Ingrese un presupuesto previsto":"Insira um orçamento estimado",
			"Ingrese un presupuesto ejecutado":"Insira um orçamento executado",
			"Seleccione una accion relacionada":"Selecione uma ação relacionada",
			"Seleccione al menos un área responsable":"Selecione pelo menos uma área responsável",
			"Seleccione un responsable":"Selecione um responsável",
			"Ingrese un nombre":"Por favor insira um nome",
			"El indicador fue eliminado correctamente":"O indicador/requisito foi removido com sucesso",
			"No se pudo cargar el indicador":"Indicador/Requisito não pôde ser carregado",
			"No se pudo eliminar la medición":"A medição não pôde ser excluída",
			"El indicador fue modificado con éxito":"O indicador/requisito foi modificado com sucesso",
			"El indicador fue registrado con éxito":"O indicador/requisito foi registrado com sucesso",
			"Seleccione un tipo":"Selecione um tipo",
			"Ingrese una fuente":"Insira uma fonte",
			"Seleccione una frecuencia":"Selecione uma frequência",
			"Seleccione una unidad de medida":"Selecione uma unidade de medida",
			"Seleccione el area responsable":"Selecione uma área responsável",
			"Seleccione un indicador":"Selecione um indicador/requisito",
			"Ingrese un valor":"Insira um valor",
			"Ingrese un límite inferior": "Insira um límite inferior",
			"Ingrese una fecha de medida":"Insira uma data de medida",
			"Ingrese una fecha": "Por favor insira uma data",
			"Seleccione una meta":"Selecione uma meta",
			"Seleccionar un desvío":"Selecione um desvio",
			"Fecha":"Data",
			"Sin indicadores":"Sem indicadores / requisitos",
			"Cargando medición": "Carregando medição",
			"Reporte Capitulo": "Relatório Capitulo",
			"Reporte Plan": "Relatório Plano",
			"Reportes": "Relatórios",
			"Ver": "Assistir",
			"No se pudo cargar los reportes": "Os relatórios não puderam ser carregados",
			"Descripción de Producto": "Descrição do Produto",
			"Si": "Sim",
			"No": "Não",
			"Volver a la selección":"Voltar para a seleção"
        }

    },
    es: {
        translation: {
            "Usuario": "Usuario",
            "Clave": "Contraseña",
            "Ingresar": "Ingresar",
            "Ingrese su clave": "Ingrese su contraseña",
            "Ingrese su usuario": "Ingrese su usuario",
            "Iniciar Sesion": "Iniciar Sesión",								 
			"Inicio": "Inicio",
			"Seleccionar Plan": "Seleccionar Plan",
			"Acciones": "Acciones",
            "Acción": "Acción",
            "Medición": "Medición",
            "Mediciones": "Mediciones",
            "Metas": "Metas",							 
			"Indicadores": "Indicadores",
			"Perfil": "Perfil",
			"Salir": "Salir",
			"No selecciono ningún plan.": "No selecciono ningún plan.",
			"Filter": "Filter",
			"Guardar": "Guardar",
			"Seleccionar Capitulos del Referencial": "Seleccionar Capitulos del Referencial",
			"Capitulos del Referencial":"Capitulos del Referencial",
			"Agregar Indicador": "Agregar Indicador",
			"Detalle de Indicador": "Detalle de Indicador",
			"Ultima medición": "Ultima medición",
			"Proxima meta": "Proxima meta",
			"Acciones realizadas": "Acciones realizadas",
            "Eliminar": "Eliminar",
			"Editar": "Editar",
			"Nombre": "Nombre",
            "Fecha de inicio": "Fecha de inicio",
            "Fecha de finalización": "Fecha de finalización",
            "Responsable": "Responsable",
            "Detalle de Acciones": "Detalle de Acciones",
            "Editar Acción": "Editar Acción",
            "Seleccionar ejes": "Seleccionar ejes",
            "Continuar": "Continuar",
            "Alta de Acciones": "Alta de Acciones",
            "Estás seguro de eliminar la accion?": "Estás seguro de eliminar la accion?",			
			"Tipo de Indicador": "Tipo de Indicador",
			"Descripción del Indicador": "Descripción del Indicador",
			"Justificación / Objetivo del Indicador": "Justificación / Objetivo del Indicador",
			"Fuente/Origen de los datos para calcular el indicador": "Fuente/Origen de los datos para calcular el indicador",
			"Frecuencia de Medición": "Frecuencia de Medición",
			"Unidad de Medida": "Unidad de Medida",
			"Método o Formula de cálculo": "Método o Formula de cálculo",
			"Áreas responsables": "Áreas responsables",
			"Área responsable": "Área responsable",
			"Detalle de Meta": "Detalle de Meta",
			"Editar Meta": "Editar Meta",
			"Alta de Meta": "Alta de Meta",
			"Agregar meta": "Agregar meta",
			"Su meta fue modificada con éxito.": "Su meta fue modificada con éxito.",
			"Su meta fue eliminada con éxito.": "Su meta fue eliminada con éxito.",
			"Su meta fue registrada con éxito.": "Su meta fue registrada con éxito.",
			"Agregar medición": "Agregar medición",
            "Alta de Medición": "Alta de Medición",
            "Seleccionar archivo": "Seleccionar archivo",
            "Ningun archivo seleccionado": "Ningun archivo seleccionado",
            "Indicador sin metas": "Indicador sin metas",
            "No seleccionó una Meta": "No seleccionó una Meta",
            "Nombre": "Nombre",
            "Fecha": "Fecha",
			"Valor de Meta": "Valor de Meta",
			"Fecha de Meta": "Fecha de Meta",
            "Detalle de Meta": "Detalle de Meta",
			"Agregar medición": "Agregar medición",
			"Detalle de Medición": "Detalle de Medición",
			"Su medición fue registrada con éxito.": "Su medición fue registrada con éxito.",
			"Su medición fue eliminada con éxito.": "Su medición fue eliminada con éxito.",
			"Su medición fue modificada con éxito.": "Su medición fue modificada con éxito.",
			"Editar Medicion": "Editar Medicion",
			"Valor": "Valor",
			"Fecha de Medida": "Fecha de Medida",
			"Fecha de proxima medida": "Fecha de proxima medida",
			"Meta": "Meta",
			"No seleccionó una Meta": "No seleccionó una Meta",
			"Tipo de desvío": "Tipo de desvío",
			"Justificación del desvío": "Justificación del desvío",
			"Medio de verificación": "Medio de verificación",
			"Seleccionar archivo": "Seleccionar archivo",
			"Ningun archivo seleccionado": "Ningun archivo seleccionado",
			"Indicador sin metas": "Indicador sin metas",
			"Agregar acción": "Agregar acción",
			"Metas": "Metas",
			"Fecha de inicio previsto": "Fecha de inicio previsto",
			"Fecha de finalización prevista": "Fecha de finalización prevista",
			"Presupuesto previsto": "Presupuesto previsto",
			"Presupuesto ejecutado": "Presupuesto ejecutado",
			"Acciones relacionadas": "Acciones relacionadas",
			"Observaciones": "Observaciones",
			"Alta de Acciones": "Alta de Acciones",
			"Estás seguro de eliminar la accion?": "Estás seguro de eliminar la accion?",
			"Su accion fue modificada con éxito.": "Su accion fue modificada con éxito.",
			"Su accion fue registrada con éxito.": "Su accion fue registrada con éxito.",
			"Su accion fue eliminada con éxito.": "Su accion fue eliminada con éxito.",		
			"Detalle de Acciones": "Detalle de Acciones",
			"Editar Acción": "Editar Acción",
            "Ultima medición": "Ultima medición",
            "Ultima meta": "Ultima meta",
            "Acciones realizadas": "Acciones realizadas",
            "Agregar Indicador": "Agregar Indicador",
            "Detalle de Indicador": "Detalle de Indicador",
            "Capítulos del Referencial": "Capítulos del Referencial",
            "Mediciones y Metas": "Mediciones y Metas",
            "Valor Medición": "Valor Medición",
            "Fecha Medición": "Fecha Medición",
            "Fecha Meta": "Fecha Meta",
			"No se pudo cargar la actividad": "No se pudo cargar la actividad",
			"Atención":"Atención",
			"No se pudo eliminar la acción":"No se pudo eliminar la acción",
			"No se pudo eliminar el indicador":"Indicador não pôde ser removido",
			"Estás seguro de eliminar la acción":"Estás seguro de eliminar la acción",
			"Estás seguro de eliminar el indicador":"Tem certeza de que deseja excluir o indicador",
			"Cargando":"Cargando",
			"Debe seleccionar al menos un eje para continuar":"Debe seleccionar al menos un eje para continuar",
			"No hay un formulario vinculado":"No hay un formulario vinculado",
			"Este plan no tiene Capítulos del Referencial":"Este plan no tiene Capítulos del Referencial",
			"Seleccione al menos una meta":"Seleccione al menos una meta",
			"Seleccione al menos un indicador":"Seleccione al menos un indicador",
			"Ingrese una fecha de inicio":"Ingrese una fecha de inicio",
			"Ingrese una fecha de fin":"Ingrese una fecha de fin",
			"Ingrese un presupuesto previsto":"Ingrese un presupuesto previsto",
			"Ingrese un presupuesto ejecutado":"Ingrese un presupuesto ejecutado",
			"Seleccione una accion relacionada":"Seleccione una acción relacionada",
			"Seleccione al menos un área responsable":"Seleccione al menos un área responsable",
			"Seleccione un responsable":"Seleccione un responsable",
			"Ingrese un nombre":"Ingrese un nombre",
			"El indicador fue eliminado correctamente":"El indicador fue eliminado correctamente",
			"No se pudo cargar el indicador":"No se pudo cargar el indicador",
			"No se pudo eliminar la medición":"No se pudo eliminar la medición",
			"El indicador fue modificado con éxito":"El indicador fue modificado con éxito",
			"El indicador fue registrado con éxito":"El indicador fue registrado con éxito",
			"Seleccione un tipo":"Seleccione un tipo",
			"Ingrese una fuente":"Ingrese una fuente",
			"Seleccione una frecuencia":"Seleccione una frecuencia",
			"Seleccione una unidad de medida":"Seleccione una unidad de medida",
			"Seleccione el area responsable":"Seleccione el area responsable",
			"Seleccione un indicador":"Seleccione un indicador",
			"Ingrese un valor":"Ingrese un valor",
			"Ingrese un límite inferior": "Ingrese un límite inferior",
			"Ingrese una fecha de medida":"Ingrese una fecha de medida",
			"Seleccione una meta":"Seleccione una meta",
			"Seleccionar un desvío":"Seleccionar un desvío",
			"Fecha":"Fecha",
			"Sin indicadores":"Sin indicadores",
			"Reporte Capitulo": "Reporte Capítulo",
			"Reporte Plan": "Reporte Plan",
			"Reportes": "Reportes",
			"Ver": "Ver",
			"No se pudo cargar los reportes": "No se pudo cargar los reportes",
			"Descripción de Producto": "Descripción de Producto",
			"Si": "Si",
			"No": "No",
			"Volver a la selección":"Volver a la selección"
        }
    }

};

export default resources;