import createReducer from "src/redux/createReducer";
import * as types from "./types";
import util from "src/extras/functions";

const initialState = {
    auth: undefined,
    profile: undefined,
    loading: false,
    msg: undefined,
    status: undefined,
};
const UserReducers = createReducer(initialState, {
    [types.GET_PROFILE](state, action) {
        return { ...state, loading: true, profile: undefined };
    },
    [types.GET_PROFILE_SUCCESS](state, action) {
        return {
            ...state, 
            loading: false,
            profile: {
                instance: { ...action.payload.data },
                included: action.payload.included ? util.generarIncludes(action.payload.data, action.payload.included) : [],
            }
        };
    },
    [types.GET_PROFILE_ERROR](state, action) {
        return { ...state, loading: false };
    },
    [types.UPDATE_PROFILE](state, action) {
        return { ...state, loading: true, status: undefined, msg: undefined };
    },
    [types.UPDATE_PROFILE_SUCCESS](state, action) {
        return {
            ...state,
            loading: false,
            profile: {
                instance: { ...action.payload.data },
                included: action.payload.included ? util.generarIncludes(action.payload.data, action.payload.included) : [],
            },
            status: "SUCCESS",
            msg: "Información personal actualizada con éxito",
        };
    },
    [types.UPDATE_PROFILE_ERROR](state, action) {
        return {
            ...state,
            loading: false,
            status: "FAILURE",
            msg: "Hubo un error al actualizar la información personal",
        };
    },
    [types.UPDATE_USER](state, action) {
        return { ...state, auth: action.payload };
    },
    [types.CLEAR_USER_STATUS](state, action) {
        return { 
            ...state, 
            status: undefined, 
            loading: undefined, 
            msg: undefined 
        };
    },
});

export default UserReducers;