import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as types from "./types";
import * as actions from "./actions";
import profileService from "src/axios/services/profile";

function* getProfile({ payload }) {
    try {
        const response = yield call(profileService.getProfile, payload);
        yield put(actions.getProfileSuccess(response.data));
    } catch (error) {
        if (error.response.status === 401) {
            window.location.href = process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}/logout` : '/logout';
        }
        yield put(actions.getProfileError(error));
    }
}

function* watchGetProfile() {
    yield takeEvery(types.GET_PROFILE, getProfile);
}

function* updateProfile({ payload }) {
    try {
        const response = yield call(profileService.updateProfile, payload);
        yield put(actions.updateProfileSuccess(response.data));
    } catch (error) {
        console.log(error);
        yield put(actions.updateProfileError(error));
    }
}

function* watchUpdateProfile() {
    yield takeEvery(types.UPDATE_PROFILE, updateProfile);
}

function* UserSaga() {
    yield all([
        fork(watchGetProfile),
        fork(watchUpdateProfile),
    ]);
}

export default UserSaga;
