import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const instance = axios.create({
    baseURL: apiUrl,
});

axios.defaults.withCredentials = true;
instance.defaults.withCredentials = false;

let token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).access_token : undefined;
if (token) {
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;
}

instance.defaults.headers.common["Accept-Language"] = "es";

const isStatusError = (error) => {
    return error.response.status === 401 || error.response.status === 403;
};

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error && error.response && isStatusError(error)) {
            window.location.href = `${process.env.PUBLIC_URL}/logout`;
        }
        return Promise.reject(error);
    }
);

export default instance;