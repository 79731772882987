import * as types from "./types";

const result = (type, payload) => {
    return {
        type: type,
        payload: payload,
    };
};

export const getProfile = payload => result(types.GET_PROFILE, payload);
export const getProfileSuccess = payload => result(types.GET_PROFILE_SUCCESS, payload);
export const getProfileError = payload => result(types.GET_PROFILE_ERROR, payload);

export const updateProfile = payload => result(types.UPDATE_PROFILE, payload);
export const updateProfileSuccess = payload => result(types.UPDATE_PROFILE_SUCCESS, payload);
export const updateProfileError = payload => result(types.UPDATE_PROFILE_ERROR, payload);

export const updateUser = payload => result(types.UPDATE_USER, payload);
export const clearUserStatus = payload => result(types.CLEAR_USER_STATUS);
