import { combineReducers } from 'redux';
import userReducer from "src/redux/reducers/user/reducer";
import EjeReducer from './reducers/eje/reducer';
import IndicadorReducer from './reducers/indicador/reducer';


const changeState = (state = 'responsive', { type, sidebarShow }) => {
    switch (type) {
        case 'set':
            return sidebarShow
        default:
            return state
    }
}

const rootReducer = combineReducers({
    sidebarShow: changeState,
    user: userReducer,
    eje: EjeReducer,
	indicador: IndicadorReducer
});

export default rootReducer