import React from 'react';
import { Route } from 'react-router-dom';
import useAuthVerification from './hooks/useAuthVerification';


const PrivateRoute = ({ render, ...rest }) => {

    useAuthVerification();

    return (
        <Route {...rest} render={render} />                            
    )
}

export default PrivateRoute;