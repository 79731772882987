import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import createSagaMiddleware from "redux-saga";
import rootReducer from './rootReducer';
import rootSagas from "./sagas";

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    rootReducer,
    process.env.REACT_APP_STATUS === 'development' 
    ? composeWithDevTools(applyMiddleware(sagaMiddleware, logger))
    : composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSagas);

export default store;