import createReducer from "src/redux/createReducer";
import * as types from "./types";
import util from "src/extras/functions";

const initialState = {
    ejesSeleccionados: undefined,
    ejesSeleccionadosIndicador: undefined,
    formSeleccionado: undefined
};
const EjeReducer = createReducer(initialState, {
    [types.UPDATE_EJES_SELECCIONADOS](state, action) {
        return { ...state, ejesSeleccionados: action.payload };
    },
    [types.UPDATE_EJES_SELECCIONADOS_INDICADOR](state, action) {
        return { ...state, ejesSeleccionadosIndicador: action.payload };
    },
    [types.UPDATE_FORM_SELECCIONADO](state, action) {
        return { ...state, formSeleccionado: action.payload };
    }
});

export default EjeReducer;