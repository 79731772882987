const funciones = {
    toTitleCase: function (str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    },
    toDateLocalFormat: function(fecha_nacimiento) {
        let newDate;
        newDate = fecha_nacimiento.split('-');
        newDate = newDate[2] + '/' + newDate[1] + '/' + newDate[0];
        return newDate;
    },
    errorMaker: function(error) {
        let messages = []; 
        if(error?.detail) {
            if(error.source && error.source.pointer && error.source.pointer.split('/')[3]) {
                let field = error.source.pointer.split('/')[3];
                messages.push({message: field, description: error.detail, type: 'error'});
            } else {
                messages.push({message: error.detail, type: 'error'});
            }
        } 
        else if (error?.error && error?.error?.detail) {
            if(error.error.source && error.error.source.pointer && error.error.source.pointer.split('/')[3]) {
                let field = error.error.source.pointer.split('/')[3];
                messages.push({message: field, description: error.error.detail, type: 'error'});
            } else {
                messages.push({message: error.error.detail, type: 'error'});
            }
        } 
        else if(error?.errors && error?.errors.length > 0){
            error.errors.forEach(
                (element) => {
                    if(element.source && element.source.pointer) {
                        let field = element.source.pointer.split('/')[3];
                        messages.push({message: field, description: element.detail, type: 'error'});
                    } else {
                        messages.push({message: element.detail, type: 'error'});
                    }
                }
            );
        }
        else {
            messages.push({message: error.error, description: error.error_description, type: 'error'});
        }
        
        return messages;
    },
    generarIncludes: function(data, included) {
        let included_return = {};
        for (var [key, relation] of Object.entries(data.relationships)) {
            included.map(include => {
                if(relation?.data?.length > 0) {
                    if(relation.data[0].type === include.type) {
                        if(!included_return[include.type.toLowerCase()]) {
                            included_return[include.type.toLowerCase()] = [];
                        }
                        relation.data.map((item, index) => {
                            if ((include.type === item?.type) && (include.id === item?.id)) {
                                included_return[include.type.toLowerCase()].push({ id: include.id, ...include.attributes });
                            }
                        })
                    }
                } else {
                    if ((include.type === relation.data?.type) && (include.id === relation.data?.id)) {
                        included_return[include.type.toLowerCase()] = { id: include.id, ...include.attributes };
                    }
                }
            });
        }
        return included_return;
    },
    returnFormError: function(errors, name) {
        return (
            errors[name] &&
                <div className="invalid-feedback help-block" style={{display: 'block'}}>
                    { errors[name].message }
                </div>
        )
        
    }
}

export default funciones;